import React,{useEffect,useState} from 'react';
import { BsFillCheckCircleFill } from "react-icons/bs";
import * as Yup from 'yup';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import { fetchWrapper } from '../../helpers';
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField} from '../../components/form'
import { toast } from "react-toastify";


function Sender(){
	const [list , setList]= useState({})
	

  const FormSchema = Yup.object().shape({
	   value: Yup.string().required('Required'),
	 	
	});

  const [formData, setFormData] = useState({
    value:list.value,
    
    
  });
useEffect(()=>{
		loadList();
	},[])

	const loadList = async()=>{
		const url = `${process.env.REACT_APP_API_URL}/setting/by-id?key=sender_id`;
    const data  = await fetchWrapper.get(url)
    setList(data.data);

    setFormData({
    value:data.data.value,
    
    
  });
	}
	

  


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = {
		   	key:'sender_id',
		   	...values
		   }

		  
	       
	    const url = `${process.env.REACT_APP_API_URL}/setting/create`;
  		await fetchWrapper.post(url,data)
  		toast(`Record has been saved `, {
	       type: "info"
	    });	
  		resetForm()
	}

	return(
		<>
			<div className="setting_detail_section">
				<Box mb="20px">
					<h2 className="main_heading">Default Sender ID</h2>
				</Box>
				<Form
		            enableReinitialize
		            validationSchema={FormSchema}
		            initialValues={formData}
		            onSubmit={onSubmit}
				>

					<Box mb="10px" w="40%">
						<InputText name="value" title="Sender ID" />
					</Box>
					
					
				 					 
				 	
				 	
				 	
			         <Button py="10px" px="25px" color="#fff" bg="#2BB673" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
					            mb={4}
					            
					            type='submit'
			          		>
			           		 Update
			          		</Button>
				</Form>
			</div>
			
		</>
	);
}

export default Sender;