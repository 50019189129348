import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const DailyMsgCount = ({ datalist }) => {
    // console.log(datalist);

    // Extract unique dates for the x-axis categories
    const categories = [...new Set(datalist?.Dailymsg?.map(item => item.date))];

    
    const formattedCategories = categories.map(date => {
        if (date === null) {
            return 'Invalid date';
        }
        const parsedDate = new Date(date);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return parsedDate.toLocaleDateString('en-GB', options);
    });



    // Get unique customers to group by customer for stacking
    const customers = [...new Set(datalist?.Dailymsg?.map(item => item.customer_name))];

    // Create series data for each customer, with message counts per date
    const series = customers.map(customer => {
        const data = categories.map(date => {
            const msgData = datalist?.Dailymsg?.find(
                item => item.date === date && item.customer_name === customer
            );
            return msgData ? msgData.message_count : 0;
        });
        return {
            name: customer,        // Customer name
            data,                  // Message count for each date
            stack: 'Messages',     // Assign the same stack to group by "Messages"
        };
    });

    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: 'Daily Messages Count',
            align: 'left',
        },
        xAxis: {
            categories: formattedCategories,
        },
        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: 'Message Count',
            },
        },
        tooltip: {
            pointFormat: '<b>{series.name}</b>: {point.y}<br/>' +
                'Total: {point.stackTotal}',
        },
        plotOptions: {
            column: {
                stacking: 'normal', // Enable stacking of columns
            },
        },
        series, // Dynamically generated series
    };

    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </>
    );
};

export default DailyMsgCount;
