import React,{useState,useEffect} from 'react';
import Pagination from "react-js-pagination";
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Input 
} from '@chakra-ui/react'
import CustomModal from '../components/CustomModal'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { fetchWrapper } from '../helpers';
import moment from 'moment';
import Header from '../components/Header'


function Contact() {
  const [list , setList]= useState([])

  const [listCount, setListCount] = useState(0); 

  const [date , setDate]= useState('')


  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
  const [isOpenEdit,setIsOpenEdit] = useState(false)   
  const [modalEditView,setModalEditView] = useState(false)

  const [loading,setLoading] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const meClose = () => {
    
     onClose();    
  }

  useEffect(()=>{
      
    loadlist(date);

    

  },[date])

  const loadlist = async(date="")=>{
    setLoading(true)

    const url = `${process.env.REACT_APP_API_URL}/contact/list?date=${date}`;

   

    const data  = await fetchWrapper.get(url)
    setList(data.rows);
    setListCount(data.count)
    setLoading(false)
  }

 

  const onChangePaginate = (pageNumber) => {
      setActivePage(pageNumber);
      // to set offset
      // console.log(pageNumber);
  };

  return(
    <>
    <Header/>
    {loading &&
        <HStack width="100%" h="100vh" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
          <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </HStack>
    }
      <div className="body_section">
       
        
        <HStack justifyContent="space-between" mb="25px">
          <Box>
            <Text fontSize="32px" fontWeight="300" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Contact Us</Text>
          </Box>
          <Box>
          	<Input type="date" onChange={(e) => setDate(e.target.value)}/>
          </Box>
        </HStack>
                           

        <div className="table_section">
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Phone Number</Th>
                  <Th>Company</Th>
                  <Th>Message</Th>
                  <Th>Date & Time</Th>



                  

                  
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
                  {list.map((rs,index)=>{
                    return(
                      <>
                          <Tr> 
                            <Td>{rs.name}</Td>
                            <Td>{rs.email}</Td>
                            <Td>{rs.phone}</Td>
                            
                            <Td>{rs.company}</Td>
                         <Td width="40%">
						  <Text style={{ 
						      whiteSpace: 'normal',
						      wordBreak: 'break-word',
						      overflowWrap: 'break-word',
						      display: '-webkit-box',
						      WebkitLineClamp: 2,
						      WebkitBoxOrient: 'vertical',
						      overflow: 'hidden',
						      textOverflow: 'ellipsis'
						    }}>
						    {rs.message}
						  </Text>
						</Td>


                            <Td>
                              <Text>{moment(rs.created_at).format('DD/MM/YY | HH:mm')}</Text>
                            </Td>
                          </Tr>
                      </>
                    )
                  })}

               
                            
                      
            
              </Tbody>
             
              
            </Table>
            <Pagination
               activePage={activePage}
               itemsCountPerPage={100}
               totalItemsCount={listCount}
               pageRangeDisplayed={100}
               onChange={onChangePaginate}
               innerClass="pagination"
               itemClass="page-item"
               activeLinkClass="page-link active"
               linkClass="page-link"
               prevPageText="Previous"
               nextPageText="Next"
              />
            
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default Contact;