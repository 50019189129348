import React ,{useEffect,useState} from 'react';
import * as Yup from 'yup';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField} from '../../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../../helpers';

function Create ({meClose,loadList}){
	
	

	const [formData, setFormData] = useState({
		 name: '',
    country_code: '', 
    sender_id:'',
    require_sender_id : '',
    code_name : '',
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   country_code: Yup.string().matches(/^\d+$/, 'Must be a Country Code').required('Required'),
	   // code_name: Yup.string().required('Required'),
	   require_sender_id: Yup.string().required('Required'),
	 	
	});

  

	

  


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	    const url = `${process.env.REACT_APP_API_URL}/country/create`;
  		await fetchWrapper.post(url,data)
 		loadList()
  		meClose();
  		toast(`Record has been saved `, {
	       type: "info"
	    });		
	}

	const senderList = {
	  data: [
	    { id: 1, name: "Yes" },
	    { id: 0, name: "No" }
	  ]
	};

	return(
		<>
			
				<Form
		            enableReinitialize
		            validationSchema={FormSchema}
		            initialValues={formData}
		            onSubmit={onSubmit}
				>

					<Box mb="10px">
						<InputText name="name" title="Country Name" />
					</Box>
					<Box mb="10px">
						<InputText name="country_code" title="Country Code Like(92)" />
					</Box>

					<Box mb="10px">
						<InputText name="code_name" title="Country Code Name (Like PK)" />
					</Box>
					<Box mb="10px">
						<InputText name="sender_id" title="Sender" />
					</Box>

					<Box mb="10px">
						<SelectField name="require_sender_id" title="Registered Sender ID" list={senderList} />
					</Box>
					
				 					 
				 	
				 	
				 	
			         <Button py="10px" px="25px" color="#fff" bg="#2BB673" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
					            mb={4}
					            
					            type='submit'
			          		>
			           		 Add New Country
			          		</Button>
				</Form>

		</>
	)
}

export default Create;