import React from 'react';
import { BsFillCheckCircleFill } from "react-icons/bs";
import List from './operator/List'

function Operator(){
	return(
		<>
			<List/>
			
		</>
	);
}

export default Operator;