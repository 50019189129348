import React,{useState,useEffect,useRef} from 'react';
import Pagination from "react-js-pagination";
import CustomModalLink from '../../components/CustomModalLink'
import Veiw from './Veiw';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  MenuDivider,
} from '@chakra-ui/react';
import { InputGroup, InputRightElement ,IconButton } from '@chakra-ui/react';
import { SearchIcon,CloseIcon } from '@chakra-ui/icons'; 
import CustomModal from '../../components/CustomModal'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { fetchWrapper } from '../../helpers';
import Papa from 'papaparse'
import { toast } from "react-toastify";
import axios from 'axios';
import moment from 'moment';

function List() {
  const [list , setList]= useState([])
  const [customer , setCustomer]= useState([])
   const [searchCustomer, setSearchCustomer] = useState("");
  const [date , setDate]= useState('')
  const [searchValue, setSearchValue] = useState('');
  const [loading,setLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [modelHeader , setModelHeader]= useState('')
  const [isOpenEdit,setIsOpenEdit] = useState(false)   
  const [modalEditView,setModalEditView] = useState(false)
  const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
const [activePage, setActivePage] = useState(1);
  const [listCount, setListCount] = useState(0); 

  useEffect(()=>{
    LoadList(
      selectedCustomer.id,
      date,
      searchValue,
      )
    loadCustomerList();
  },[selectedCustomer,date])
  

   const LoadList = async(customer_id ="",date="",searchValue="") =>{
    setLoading(true)
    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : "";
    const url = `${process.env.REACT_APP_API_URL}/webhook/list?customer_id=${customer_id}&date=${date}&message_id=${searchValue}`;
    const data  = await fetchWrapper.get(url)
    setList(data.rows)
    setListCount(data.count)
    setLoading(false)
  }


  const loadCustomerList = async ()=>{
    const url = `${process.env.REACT_APP_API_URL}/customer/list`;
    const data  = await fetchWrapper.get(url)
    setCustomer(data.data);
  }

  

  const filteredCustomer = customer?.filter((rs) =>
    rs.business_name.toLowerCase().includes(searchCustomer.toLowerCase())
  );

  const handleSelect = (type,rs) => {
      const { id, business_name } = rs;
      console.log(rs)
    if (type === 'customer') {
      setSelectedCustomer({ id, business_name });
    }
  };

  const meSearch = (type,e)=>{

    if(type === "customer"){
      setSearchCustomer(e.target.value)
    }
   
  }

  const handleSearchClick = () => {
    LoadList(
      selectedCustomer.id,
      date,
      searchValue,
      )
    
    // setSearchValue('')
  };

  const clearSearch = () => {    
    setSearchValue('')
    LoadList(selectedCustomer.id,
      date);
  };

  const meCloseEdit = () => {
      
       setIsOpenEdit(false);
       
  }

  const meVeiw = (type ,rs) => {
    if(type === "veiw_data"){
      setIsOpenEdit(true);
      setModelHeader('Data Posted')
      setModalEditView(<Veiw  meCloseEdit={meCloseEdit} rs={rs}  type={type} />);
    }

    if(type === "veiw_response"){
      setIsOpenEdit(true);
      setModelHeader('Resposne')
      setModalEditView(<Veiw  meCloseEdit={meCloseEdit} rs={rs} type={type} />);
    }
    
  }


   const onChangePaginate = (pageNumber) => {
      setActivePage(pageNumber);
      // to set offset
      // console.log(pageNumber);
  };

  return(
    <>
    <CustomModalLink
          showModalButtonText={modelHeader}
          modalHeader={modelHeader}
          isOpenEdit={isOpenEdit}
          onCloseEdit={meCloseEdit}
          onOpenEdit={onOpenEdit}
          
          modalBody={modalEditView}
    />
    {loading &&
          <HStack width="100%" h="100vh" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
            <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </HStack>
      }
    
      <div className="body_section">
       
        
        <HStack justifyContent="space-between" mb="25px" w="100%">
          <Box w="30%">
            <Text fontSize="32px" fontWeight="300" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Webhook Logs</Text>
          </Box>
          <Box w="65%">
          <HStack w="100%" justifyContent="flex-end">
            <Box w="395px">
              <InputGroup>
                <Input type="text" placeholder="Search using Message ID or Phone No" 
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />

                <InputRightElement width="unset">
                <IconButton 
                  aria-label="Search"
                  icon={<SearchIcon color="gray.300" />} 
                  onClick={handleSearchClick}
                  variant="link"
                  pointerEvents="auto"
                />
                <IconButton
                aria-label="Clear"
                icon={<CloseIcon color="gray.300" />}
                onClick={clearSearch}
                variant="link"
                pointerEvents="auto"
              />
              </InputRightElement>

              </InputGroup>
            </Box>

            <Box>
              <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} className="menu-filter">
                   {selectedCustomer.business_name || "Customer"}
                </MenuButton>
                <MenuList>
                  <Box p="10px">
                  <Input
                    placeholder="Search Customer"
                    value={searchCustomer}
                    onChange={(e) => meSearch('customer',e)}            
                  />
                  </Box>

                  <MenuItem onClick={() => handleSelect("customer",'')}>All Show</MenuItem>
                

                  {filteredCustomer?.slice(0,10).map((rs,index)=>{
                          return(
                            <>
                              <MenuItem key={index} onClick={() => handleSelect("customer",rs)}>{rs.business_name}</MenuItem>
                            </>
                          )
                        })}
                </MenuList>
              </Menu>
            </Box>
            <Box>
              <Input type="date"  value={date} onChange={(e) => setDate(e.target.value)} />
            </Box>

          </HStack>
          </Box> 
        </HStack>
                           

        <div className="table_section">
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Date & Time</Th>
                  <Th>Customer</Th>
                  <Th>Message ID</Th>
                  <Th>Url</Th>
                  <Th>Data Posted</Th>
                  <Th>Response</Th>            

                  

                  
                </Tr>
              </Thead>

              <Tbody fontSize="14px">

              {list.map((rs, index) => {
              return(
                <>
                  <Tr key={index}> 
                <Td>{moment(rs.created_at).format('DD/MM/YY | HH:mm')}</Td>
                <Td>{rs?.Customer?.business_name}</Td>
                <Td>{rs.message_id}</Td>
                <Td>{rs.url}</Td>
                <Td onClick={()=> meVeiw('veiw_data',rs)} style={{color:'#1F5AF4' ,cursor:'pointer'}}> Veiw</Td>
                <Td onClick={()=> meVeiw('veiw_response',rs)} style={{color:'#1F5AF4' ,cursor:'pointer'}}>Veiw</Td>

              </Tr>
                </>
              )
              
            })}


                  
                        
                      
                   
              
                  


               
                            
                      
            
              </Tbody>
             
              
            </Table>

           <Pagination
               activePage={activePage}
               itemsCountPerPage={100}
               totalItemsCount={listCount}
               pageRangeDisplayed={100}
               onChange={onChangePaginate}
               innerClass="pagination"
               itemClass="page-item"
               activeLinkClass="page-link active"
               linkClass="page-link"
               prevPageText="Previous"
               nextPageText="Next"
              />
            
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default List;