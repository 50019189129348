import React from 'react';
import { BsFillCheckCircleFill } from "react-icons/bs";
import List from './user/List'

function User(){
	return(
		<>
			<List/>
			
		</>
	);
}

export default User;