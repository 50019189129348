import React ,{useEffect,useState} from 'react';
import * as Yup from 'yup';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField} from '../../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../../helpers';

function Create ({meCloseEdit,loadList,rs}){
	
	

	const [formData, setFormData] = useState({
    name: rs.name,
    color: rs.color,  
    msg_status:rs.msg_status,
    id:rs.id,
    
    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   color: Yup.string().required('Required'),
	   msg_status: Yup.string().required('Required'),
	 	
	});

  

	

  


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	    const url = `${process.env.REACT_APP_API_URL}/status/update`;
  		await fetchWrapper.post(url,data)
 		loadList()
  		meCloseEdit();
  		toast(`Record has been Update `, {
	       type: "info"
	    });		
	}

	const status_list = {
	  data: [
	    { id: 1, name: "Success" },
	    { id: 0, name: "Failed" }
	  ]
	};

	return(
		<>
			
				<Form
		            enableReinitialize
		            validationSchema={FormSchema}
		            initialValues={formData}
		            onSubmit={onSubmit}
				>

					<Box mb="10px">
						<InputText name="name" title="Message Name" />
					</Box>
					<Box mb="10px">
						<InputText name="color" title="Message Color" />
					</Box>
					<Box mb="10px">
						<SelectField name="msg_status" title="Message Status" list={status_list}/>
					</Box>
					
				 					 
				 	
				 	
				 	
			         <Button py="10px" px="25px" color="#fff" bg="#2BB673" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
					            mb={4}
					            
					            type='submit'
			          		>
			           		Update Status
			          		</Button>
				</Form>

		</>
	)
}

export default Create;