import React,{useState,useEffect,useRef} from 'react';
import Pagination from "react-js-pagination";
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  MenuDivider,
} from '@chakra-ui/react'
import CustomModal from '../../components/CustomModal'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { fetchWrapper } from '../../helpers';
import Papa from 'papaparse'
import { toast } from "react-toastify";
import axios from 'axios';
function List() {

   const inputRef = useRef(null);
  const [list , setList]= useState([])
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
  const [isOpenEdit,setIsOpenEdit] = useState(false)   
  const [modalEditView,setModalEditView] = useState(false)
  const [activePage, setActivePage] = useState(1);
  const [listCount, setListCount] = useState(0); 
  const [loading,setLoading] = useState(false);



  const [country , setCountry]= useState([])
  const [selectedCountry, setSelectedCountry] = useState('');

  const [operator , setOperator]= useState([])
  const [selectedOperator, setSelectedOperator] = useState('');

  const [searchCountry, setSearchCountry] = useState("");
  const [searchOperator, setSearchOperator] = useState("");
  const [type,setType] = useState('')
  const meClose = () => {
    
     onClose();    
  }

  useEffect(()=>{

    loadList(
      selectedCountry.id,
      selectedOperator.id,
    );
    CountryList();
    OperatorList()

  },[activePage,selectedCountry,selectedOperator])

  const loadList = async(countryId = "", operatorId = "")=>{
    setLoading(true)
    const url = `${process.env.REACT_APP_API_URL}/price/list?page=${activePage}&country_id=${countryId}&operator_id=${operatorId}`;
    const data = await fetchWrapper.get(url);
    setList(data.rows)
    setListCount(data.count)
    setLoading(false)
  }

  const CountryList = async ()=>{
    const url = `${process.env.REACT_APP_API_URL}/country/list`;
    const data  = await fetchWrapper.get(url)
    setCountry(data.rows);
  }

  const OperatorList = async ()=>{
    const url = `${process.env.REACT_APP_API_URL}/operator/list`;
    const data  = await fetchWrapper.get(url)
    setOperator(data.rows);
  }


   const handleFileChange = (e) => {
  const file = e.target.files[0];

  if (file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);

    const url = `${process.env.REACT_APP_API_URL}/csv`;

    axios.post(url, formData)
  .then((response) => {
    // console.log(response.data);
    toast.success(response.data);
    loadList();
  })
  .catch((error) => {
    // console.error(error); 
    toast.error(`Failed to upload file: ${error.message}`);
  });

    if (inputRef.current) {
      inputRef.current.value = null;
    }
  }
};


  const handleClick = (type) => {
    setType(type);
    inputRef.current.click();
  };

  const onChangePaginate = (pageNumber) => {
      setActivePage(pageNumber);
      // to set offset
      // console.log(pageNumber);
  };

  const handleSelect = (type,rs) => {
      const { id, name } = rs;
    if (type === 'country') {
      setSelectedCountry({ id, name });
    } 
    else if (type === 'operator') {
      setSelectedOperator({ id, name });
    }
  };

  const meSearch = (type,e)=>{

    if(type === "country"){
      setSearchCountry(e.target.value)
    }
    if(type === "operator"){
      setSearchOperator(e.target.value)
    }
  }
  const filteredCountries = country?.filter((rs) =>
    rs.name.toLowerCase().includes(searchCountry.toLowerCase())
  );

  const filteredOperator = operator?.filter((rs) =>
    rs.name.toLowerCase().includes(searchOperator.toLowerCase())
  );

  const meCsv = async() => {
    const fetch = `${process.env.REACT_APP_API_URL}/price/list`;
    const data = await fetchWrapper.get(fetch);
    const listed = data.rows

    if (listed.length === 0) {
      toast.error("No data available to download");
      return;
    }

    const csvData = listed.map((rs) => ({
      Country: rs?.Operator?.Country?.name || '',
      Operator: rs?.Operator?.name || '',
      MCC: rs?.Operator?.mcc || '',
      MNC: rs?.Operator?.mnc || '',
      Prefix: rs?.Operator?.prefix || '',
      "Sender ID": rs?.Operator?.sender_id === 0 ? "Default" : rs?.Operator?.sender_id,
      "SMS Cost": rs.sms_cost || '',
      "WhatsApp Cost": rs.whatsapp_cost || '',
    }));

    const csv = Papa.unparse(csvData);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "price_list.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success("File downloaded successfully");
  };


  return(
    <>

    {loading &&
          <HStack width="100%" h="100vh" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
            <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </HStack>
      }
    <input type="file" onChange={handleFileChange} accept=".csv"    ref={inputRef} style={{display:'none'}} />
      <div className="body_section">
       
        
        <HStack justifyContent="space-between" mb="25px">
          <Box>
            <Text fontSize="32px" fontWeight="300" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Price List</Text>
          </Box>
            <Box>
              <HStack>
                  <Box>
                    <Menu>
                      <MenuButton as={Button} rightIcon={<ChevronDownIcon />} className="menu-filter">
                        {selectedCountry.name || "Country"}
                      </MenuButton>
                      <MenuList>
                        <Box p="10px">
                        <Input

                          placeholder="Search country"
                          value={searchCountry}
                          onChange={(e) => meSearch('country',e)}
                        />
                        </Box>
                      

                        <MenuItem onClick={() => handleSelect("country",'')}>All Show</MenuItem>
                        {filteredCountries?.slice(0,10).map((rs,index)=>{
                          return(
                            <>
                              <MenuItem key={index} onClick={() => handleSelect("country",rs)}>{rs.name}</MenuItem>
                            </>
                          )
                        })}

                        
                      </MenuList>
                    </Menu>
                  </Box>
                  <Box>
                   <Menu>
                      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}  className="menu-filter">
                         {selectedOperator.name || "Operator"}
                      </MenuButton>
                      <MenuList>
                        <Box p="10px">
                        <Input
                            
                          placeholder="Search operator"
                          value={searchOperator}
                          onChange={(e) => meSearch('operator',e)}
                        />
                        </Box>
                        <MenuItem onClick={() => handleSelect("operator",'')}>All Show</MenuItem>
                        {filteredOperator?.slice(0,10).map((rs,index)=>{
                          return(
                            <> 
                              <MenuItem key={index} onClick={() => handleSelect("operator",rs)}>{rs.name}</MenuItem>
                            </>
                          )
                        })}

                        
                      </MenuList>
                    </Menu>
                  </Box>
                  <Box>
                    <Button className="filter-button" onClick={()=> meCsv()}>Download Price List</Button>
                  </Box>
                  <Box>
                    <Menu>
                      <MenuButton as={Button}   className="menu-filter-button">
                        Import Updated Price List
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={()=> handleClick('sms')}>Import SMS Rates</MenuItem>
                        <MenuItem  onClick={()=> handleClick('whatsapp')}>Import WhatsApp Rates</MenuItem>
                        
                      </MenuList>
                    </Menu>
                  </Box>

              </HStack>
            </Box>     
        </HStack>
                           

        <div className="table_section">
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Country</Th>
                  <Th>Operator</Th>
                  <Th>MCC</Th>
                  <Th>MNC</Th>
                  <Th>Prefix</Th>
                  <Th>Sender ID</Th>
                  <Th>SMS Cost</Th>
                  <Th>WhatsApp Cost</Th>
                  <Th>Last Updated</Th>
                 


                  

                  
                </Tr>
              </Thead>

              <Tbody fontSize="14px">

                  {list.map((rs,index)=>{
                    return(
                      <>
                        <Tr key={index}> 
                          <Td>{rs?.Operator?.Country?.name}</Td>
                          <Td>{rs?.Operator?.name}</Td>
                          <Td>{rs?.Operator?.mcc}</Td>
                          <Td>{rs?.Operator?.mnc}</Td>
                          <Td>{rs?.Operator?.prefix}</Td>
                          <Td>{rs?.Operator?.sender_id === 0 ? "Deafult" : rs?.Operator?.sender_id}</Td>
                          <Td>{rs.sms_cost}</Td>
                          <Td>{rs.whatsapp_cost}</Td>
                          <Td>27/08/2024</Td>
                          
                          
                    
                        </Tr>
                      </>
                    )
                  })}
              
                  


               
                            
                      
            
              </Tbody>
             
              
            </Table>

            <Pagination
               activePage={activePage}
               itemsCountPerPage={100}
               totalItemsCount={listCount}
               pageRangeDisplayed={100}
               onChange={onChangePaginate}
               innerClass="pagination"
               itemClass="page-item"
               activeLinkClass="page-link active"
               linkClass="page-link"
               prevPageText="Previous"
               nextPageText="Next"
              />
            
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default List;