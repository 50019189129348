import React from 'react'
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'

const CountryWiseMsg = ({ datalist }) => {

    // Extract unique dates
    const categories = [...new Set(datalist?.CountryWiseCount?.map(item => item.dates))];

    // Format the categories (dates)
    const formattedCategories = categories.map(date => {
        if (date === null) {
            return 'Invalid date';
        }
        const parsedDate = new Date(date);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return parsedDate.toLocaleDateString('en-GB', options);
    });

    // Get unique countries
    const countries = [...new Set(datalist?.CountryWiseCount?.map(item => item.country_name))];

    // Create series data for each country
    const series = countries.map(country => {
        const data = categories.map(date => {
            const msgData = datalist?.CountryWiseCount?.find(
                item => item.dates === date && item.country_name === country
            );
            return msgData ? msgData.total_sms_count : 0;
        });
        return {
            name: country,  // Country name
            data,           // Message count for each date
            stack: 'Messages',
        };
    });

    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Country Wise Messages Count',
            align: 'left'
        },
        xAxis: {
            categories: formattedCategories,
        },
        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: ''
            }
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            }
        },
        series
    };

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </>
    );
};

export default CountryWiseMsg;
