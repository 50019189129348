import React ,{useState,useEffect} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Input
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import CustomModal from '../../../components/CustomModal'
import CustomModalLink from '../../../components/CustomModalLink'
import Create from './Create';
import Edit from './Edit';
import { fetchWrapper } from '../../../helpers';
import Pagination from "react-js-pagination";

function List() {

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	const [list,setList] = useState([])
	const [activePage, setActivePage] = useState(1);
  const [listCount, setListCount] = useState(0); 
  const [loading,setLoading] = useState(false);


  const [selectedCountry, setSelectedCountry] = useState('');
  const [searchCountry, setSearchCountry] = useState("");
  const [country , setCountry]= useState([])


	const meClose = () => {
		
	   onClose();	   
	}

	useEffect(()=>{
		loadList(selectedCountry.id);
		CountryList()
	},[activePage,selectedCountry])

	const loadList = async(countryId="")=>{
		setLoading(true)
		const url = `${process.env.REACT_APP_API_URL}/operator/list?page=${activePage}&country_id=${countryId}`;
    const data  = await fetchWrapper.get(url)
    setList(data.rows);
    setListCount(data.count)
    setLoading(false)
	}

	const CountryList = async ()=>{
    const url = `${process.env.REACT_APP_API_URL}/country/list`;
    const data  = await fetchWrapper.get(url)
    setCountry(data.rows);
  }

	const meEdit = (rs) => {
		setIsOpenEdit(true);
		setModalEditView(<Edit  meCloseEdit={meCloseEdit} rs={rs}  loadList={loadList} />)		
	}

	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}

	const onChangePaginate = (pageNumber) => {
      setActivePage(pageNumber);
      // to set offset
      console.log(pageNumber);
  };

  const meDelete = async(rs)=>{
  	const aa = window.confirm('Delete Operator');
  	if(aa){
	  	const url = `${process.env.REACT_APP_API_URL}/operator/delete/${rs.id}`;
	   	await fetchWrapper.delete(url)	;
	   	loadList();
  	}
  }

  const handleSelect = (type,rs) => {
      const { id, name } = rs;
    if (type === 'country') {
      setSelectedCountry({ id, name });
    } 
  };

  const meSearch = (type,e)=>{

    if(type === "country"){
      setSearchCountry(e.target.value)
    }
  }
  const filteredCountries = country?.filter((rs) =>
    rs.name.toLowerCase().includes(searchCountry.toLowerCase())
  );

	return(
		<>
		{loading &&
          <HStack width="100%" h="100vh" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
            <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </HStack>
      }
		 <CustomModalLink
          showModalButtonText="Operator Edit"
          modalHeader="Operator Edit"
          isOpenEdit={isOpenEdit}
          onCloseEdit={meCloseEdit}
          onOpenEdit={onOpenEdit}
          
          modalBody={modalEditView}
    />
			<div className="setting_detail_section">

				<HStack justifyContent="space-between">
						<Box>
							<h2 className="main_heading">List of Operators</h2>
						</Box>
						<Box>
							<HStack>
									<Box>
                    <Menu>
                      <MenuButton as={Button} rightIcon={<ChevronDownIcon />} className="menu-filter">
                        {selectedCountry.name || "Country"}
                      </MenuButton>
                      <MenuList>
                        <Box p="10px">
                        <Input

                          placeholder="Search country"
                          value={searchCountry}
                          onChange={(e) => meSearch('country',e)}
                        />
                        </Box>
                      

                        <MenuItem onClick={() => handleSelect("country",'')}>All Show</MenuItem>
                        {filteredCountries?.slice(0,10).map((rs,index)=>{
                          return(
                            <>
                              <MenuItem key={index} onClick={() => handleSelect("country",rs)}>{rs.name}</MenuItem>
                            </>
                          )
                        })}

                        
                      </MenuList>
                    </Menu>
                  </Box>
									<Box>
										<Button className="simple_button">Download Operator List</Button>
									</Box>
									<Box>
										<CustomModal
								            showModalButtonText="Add New Operator"
								            modalHeader="Add Operator"
								           	isOpen={isOpen}
								           	onClose={onClose}
								           	onOpen={onOpen}
								            modalBody=<Create meClose={meClose} loadList={loadList}  />
							            />
									</Box>
							</HStack>
						</Box>
				</HStack>



				<div className="table_section">
		          <TableContainer width="100%">
		            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
		              <Thead >
		                <Tr>
		                  <Th>Operator Name</Th>
		                  <Th>Country</Th>
		                  <Th>MCC</Th>
		                  <Th>MNC</Th>
		                  <Th>SMS Cost</Th>
		                 	<Th>WhatsApp Cost</Th>		                 
		                 	<Th>Prefix</Th>
		                  <Th></Th>


		                  

		                  
		                </Tr>
		              </Thead>

		              <Tbody fontSize="14px">
		              			{list?.map((rs,index)=>{
		              				return(
		              					<>
		              						<Tr> 
					                            <Td>{rs.name}</Td>
					                            <Td>{rs?.Country?.name}</Td>
					                            <Td>{rs.mcc}</Td>
					                            <Td>{rs.mnc}</Td>
					                            <Td>{rs.sms_cost}</Td>
                          						<Td>{rs.whatsapp_cost}</Td>
                          						<Td>{rs.prefix}</Td>

					                            
					                            
					                          	<Td style={{color:"#0000EE"}}>
						                            <Menu>
						                              <MenuButton fontSize="14px" >
						                              Action
						                                
						                                  <ChevronDownIcon />
						                              </MenuButton>

						                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
						                                <MenuItem  h="16px" fontSize="12px" onClick={()=> meEdit(rs)}>Edit</MenuItem>					                                
						                                <MenuDivider/>
						                                <MenuItem  h="16px" fontSize="12px" onClick={()=> meDelete(rs)}>Delete</MenuItem>
						                              </MenuList>
						                            </Menu>
				                           		</Td>
					                      
				                            </Tr>
		              					</>
		              				)
		              			})}
				          		
				         

			             
		                           	
			                    
		            
		              </Tbody>
		             
		              
		            </Table>
		            <Pagination
	               activePage={activePage}
	               itemsCountPerPage={100}
	               totalItemsCount={listCount}
	               pageRangeDisplayed={100}
	               onChange={onChangePaginate}
	               innerClass="pagination"
	               itemClass="page-item"
	               activeLinkClass="page-link active"
	               linkClass="page-link"
	               prevPageText="Previous"
	               nextPageText="Next"
	              />
		          </TableContainer>
		        </div>
				
			</div>
		</>
	)
}


export default List
