import React ,{useEffect,useState} from 'react';
import * as Yup from 'yup';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField} from '../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../helpers';




function Create ({meCloseEdit,loadList,rs}){
	const [TypeList,SetTypeList] = useState([]);
	const [Volumn,SetVolumnList] = useState([]);
	const [accountList,setAccountList] = useState([]);

	const [formData, setFormData] = useState({
    name: rs.name,
    
   
    sms_rate:rs.sms_rate,
    whatsapp_rate:rs.whatsapp_rate,
    id:rs.id
    
    
    
  });

  const FormSchema = Yup.object().shape({
	  
   	sms_rate: Yup.string().required('Required'),
   	whatsapp_rate: Yup.string().required('Required'),
	 	
	});

  

	useEffect(() => {
       
       
        
    }, []);

  	

  


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	    const url = `${process.env.REACT_APP_API_URL}/customer/update`;
  		await fetchWrapper.post(url,data)
 		loadList()
  		meCloseEdit();
  		toast(`Record has been update `, {
	       type: "info"
	    });		
	}

	return(
		<>
			
				<Form
		            enableReinitialize
		            validationSchema={FormSchema}
		            initialValues={formData}
		            onSubmit={onSubmit}
				>

					
				
					<Box mb="10px">
						{rs.business_name}
					</Box>

					<HStack w="100%" mb="30px">
						<Box w="30%">
							<InputText name="sms_rate" title="Sms Rate (€)" placeholder="Please Enter %"  />
						</Box>
						<Box w="70%">
							
							<InputText name="whatsapp_rate" title="Whatsapp Rate (€)" placeholder="Please Enter %"  />
						</Box>
					</HStack>

					
				 	
				 	
			         <Button py="10px" px="25px" color="#fff" bg="#2BB673" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
					            mb={4}
					            
					            type='submit'
			          		>
			           		Add Rates
			          		</Button>
				</Form>

		</>
	)
}

export default Create;