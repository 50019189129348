import React from 'react';
import { BsFillCheckCircleFill } from "react-icons/bs";
import List from './country/List'

function Country(){
	return(
		<>
			<List/>
			
		</>
	);
}

export default Country;