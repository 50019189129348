import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const CustomerWiseMessageCount = ({ datalist }) => {
   
    const customerWiseSmsCount = datalist?.CustomerWiseSmsCount || [];

   
    const chatvalue = [
        {
            name: 'Rejected',
            data: customerWiseSmsCount.map(item => parseInt(item.failed_count) || 0),
            stack: 'messages',
            color:'#FF3F3F'
        },
        {
            name: 'Successful',
            data: customerWiseSmsCount.map(item => parseInt(item.success_count) || 0),
            stack: 'messages',
            color:'#16CE66'
        }
    ];

    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: 'Customer Wise Messages Count',
            align: 'left',
        },
        xAxis: {
            categories: customerWiseSmsCount.map(item => item.name || 'Unknown'),
        },
        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: '',
            },
        },
        tooltip: {
            pointFormat: '<b>{series.name}</b>: {point.y}<br/>' +
                'Total: {point.stackTotal}',
        },
        plotOptions: {
            column: {
                stacking: 'normal',
            },
        },
        series: chatvalue,
    };

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </>
    );
};

export default CustomerWiseMessageCount;
