import React ,{useEffect,useState} from 'react';
import * as Yup from 'yup';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField} from '../../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../../helpers';

function Create ({meClose,loadList}){
	const [country,setCountry] = useState([])
	

	const [formData, setFormData] = useState({
    name: '',
    country_id: '',
    mcc:'',
    mnc:'',  
    sms_cost:'',
    whatsapp_cost:'',
    prefix:'',
    
  });

  const FormSchema = Yup.object().shape({
	  name: Yup.string().required('Required'),
	  country_id: Yup.string().required('Required'),
	  mcc: Yup.string().required('Required'),
	  mnc: Yup.string().required('Required'),
	  sms_cost: Yup.string().required('Required'),
	  whatsapp_cost: Yup.string().required('Required'),
	  prefix: Yup.string().required('Required'),
	 	
	});

  useEffect(()=>{
  	loadCountryList()
  },[])

  const loadCountryList = async()=>{
		const url = `${process.env.REACT_APP_API_URL}/country/list`;
    const data  = await fetchWrapper.get(url)
    setCountry(data);
	}

	

  


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	    const url = `${process.env.REACT_APP_API_URL}/operator/create`;
  		await fetchWrapper.post(url,data)
 		loadList()
  		meClose();
  		toast(`Record has been saved `, {
	       type: "info"
	    });		
	}

	return(
		<>
			
				<Form
		            enableReinitialize
		            validationSchema={FormSchema}
		            initialValues={formData}
		            onSubmit={onSubmit}
				>

					<Box mb="0px">
						<InputText name="name" title="Operator Name" />
					</Box>
					<Box mb="0px">
						<SelectField name="country_id" title="Country" list={country} />
					</Box>

					<Box mb="0px">
						<InputText name="mcc" title="MCC" />
					</Box>
					<Box mb="0px">
						<InputText name="mnc" title="MNC" />
					</Box>

					<Box mb="0px">
						<InputText name="sms_cost" title="SMS Cost" />
					</Box>
					<Box mb="0px">
						<InputText name="whatsapp_cost" title="Whatsapp Cost" />
					</Box>
					<Box mb="0px">
						<InputText name="prefix" title="Prefix" />
					</Box>
					
				 					 
				 	
				 	
				 	
			         <Button py="10px" px="25px" color="#fff" bg="#2BB673" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
					            mb={4}
					            
					            type='submit'
			          		>
			           		 Add New Operator
			          		</Button>
				</Form>

		</>
	)
}

export default Create;