import React from 'react'
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
import { HStack, Box, Image ,Text} from '@chakra-ui/react';

const CustomerTypeChart = ({datalist}) => {

     const chartvalue = datalist?.TypeCount?.map((rs)=>{
        return {
                name: rs.customer_type,
                y: rs.customer_count,
                color: rs.customer_type === 'Prepaid' ?'#F5B041' : '#5DADE2'
            }
    })

    const options = {
        chart: {
            type: 'pie',
            spacing: [20, 0, 20, 0],
        },
        title: {
            text: 'Customer Type',
            align: 'left' // Align the title to the left
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                cursor: 'pointer',                
                size: '100%',
            },
            series: {
                borderRadius: 5,
                dataLabels: [
                    {
                        enabled: true,
                        distance: 15,
                        format: '{point.name}'
                    }, 
                    {
                        enabled: true,
                        distance: '-30%',
                        filter: {
                            property: 'percentage',
                            operator: '>',
                            value: 5
                        },
                        format: '{point.y}%',
                        style: {
                            fontSize: '0.9em',
                            textOutline: 'none'
                        }
                    }
                ]
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
        },
        series: [
            {
                name: 'Messages',
                colorByPoint: true,
                data: chartvalue,
            }
        ]
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center',flexDirection: 'column' }}>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />

            
            <HStack justifyContent="center">
                <Box className="chart_title_box_section">
                    <HStack>
                        <Box className="chart_title_box" bg="#5DADE2"></Box>
                        <Text>Prepaid</Text>
                    </HStack>
                </Box>

                <Box className="chart_title_box_section">
                    <HStack>
                        <Box className="chart_title_box" bg="#F5B041"></Box>
                        <Text>PostPaid</Text>
                    </HStack>
                </Box>
            </HStack>
        </div>
    )
}

export default CustomerTypeChart;
