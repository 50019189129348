import React ,{useState,useEffect} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import CustomModal from '../../../components/CustomModal'
import CustomModalLink from '../../../components/CustomModalLink'
import Create from './Create';
import Edit from './Edit';

import { fetchWrapper } from '../../../helpers';

function List() {

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	const [list,setList] = useState([])
	const [loading,setLoading] = useState(false);
	const meClose = () => {
		
	   onClose();	   
	}

	useEffect(()=>{
		loadList();
	},[])

	const loadList = async()=>{
		setLoading(true)
		const url = `${process.env.REACT_APP_API_URL}/status/list`;
    	const data  = await fetchWrapper.get(url)
    	setList(data.data);
    	setLoading(false)
	}

	const meEdit = (rs) => {
		setIsOpenEdit(true);
		setModalEditView(<Edit  meCloseEdit={meCloseEdit} rs={rs}  loadList={loadList} />)		
	}

	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}



	return(
		<>

		{loading &&
          <HStack width="100%" h="100vh" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
            <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </HStack>
      }
		 <CustomModalLink
          showModalButtonText="Status Edit"
          modalHeader="Status Edit"
          isOpenEdit={isOpenEdit}
          onCloseEdit={meCloseEdit}
          onOpenEdit={onOpenEdit}
          
          modalBody={modalEditView}
    />
			<div className="setting_detail_section">

				<HStack justifyContent="space-between">
						<Box>
							<h2 className="main_heading">Message Status</h2>
						</Box>
						<Box>
							<HStack>
									<Box>
										<Button className="simple_button">Download Status List</Button>
									</Box>
									<Box>
										<CustomModal
								            showModalButtonText="Add New Status"
								            modalHeader="Add Status"
								           	isOpen={isOpen}
								           	onClose={onClose}
								           	onOpen={onOpen}
								            modalBody=<Create meClose={meClose} loadList={loadList}  />
							            />
									</Box>
							</HStack>
						</Box>
				</HStack>



				<div className="table_section">
		          <TableContainer width="100%">
		            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
		              <Thead >
		                <Tr>
		               	 <Th>Id</Th>
		                  <Th>Name</Th>
		                  <Th>Message Color</Th>
		                  <Th>Message Status</Th>
		                 
		                 
		                  <Th></Th>


		                  

		                  
		                </Tr>
		              </Thead>

		              <Tbody fontSize="14px">
		              			{list.map((rs,index)=>{
		              				return(
		              					<>
		              						<Tr> 		<Td>{rs.id}</Td>
					                            <Td>{rs.name}</Td>
					                            <Td>{rs.color}</Td>
					                             <Td style={{color: rs.color}}>{rs.msg_status === "0" ? "Failed" : "Success"}</Td>
					                            

					                            
					                            
					                          	<Td style={{color:"#0000EE"}}>
						                            <Menu>
						                              <MenuButton fontSize="14px" >
						                              Action
						                                
						                                  <ChevronDownIcon />
						                              </MenuButton>

						                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
						                                <MenuItem  h="16px" fontSize="12px" onClick={()=> meEdit(rs)}>Edit</MenuItem>						                                
						                              </MenuList>
						                            </Menu>
				                           		</Td>
					                      
				                            </Tr>
		              					</>
		              				)
		              			})}
				          		
				         

			             
		                           	
			                    
		            
		              </Tbody>
		             
		              
		            </Table>
		            
		          </TableContainer>
		        </div>
				
			</div>
		</>
	)
}


export default List
